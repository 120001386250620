<template>
  <div class="container-top" style="background-color:black">
    <div class="pc container">
      <div class="col-12">
        <div class="text-center prime-bg">
          <div class="size-24 margin-bottom-20 primary">내 사업에 꼭 맞춘 커스텀 플랫폼을 만들고 싶다면?</div>
          <div class="primary margin-bottom-20 weight-700" style="font-size:60px;line-height:84px">Prime</div>
          <div class="body1 gray">완벽함을 위한 VIP 솔루션</div>
          <div class="body1 gray">A to Z, 서비스의 모든 디자인과 기능을 내가 원하는대로</div>
        </div>
        <div class="col-12 arrow-box flex-justify">
          <div class="flex-align">
            <div class="text-center" style="margin-right:40px">
              <div class="margin-bottom-4 primary body3-bold">step 1</div>
              <div class="size-20" style="margin-bottom:10px;color:white">상담</div>
              <div style="width:40px;height:61px" class="flex-center">
                <div class="dot-prime"></div>
              </div>
            </div>
            <div>
              <div class="margin-bottom-4 primary body3-bold">step 2</div>
              <div class="size-20" style="margin-bottom:10px;color:white">기획</div>
              <svg width="237" height="61" viewBox="0 0 237 61" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M237 30.3594L192 56.3401L192 4.37861L237 30.3594Z" fill="white"/>
              <rect y="17.3594" width="207" height="26" fill="white"/>
              </svg>
            </div>
            <div>
              <div class="margin-bottom-4 primary body3-bold">step 3</div>
              <div class="size-20" style="margin-bottom:10px;color:white">디자인</div>
              <svg width="237" height="61" viewBox="0 0 237 61" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M237 30.3594L192 56.3401L192 4.37861L237 30.3594Z" fill="#BDBDBD"/>
              <rect y="17.3594" width="207" height="26" fill="#BDBDBD"/>
              </svg>
            </div>
            <div>
              <div class="margin-bottom-4 primary body3-bold">step 4</div>
              <div class="size-20" style="margin-bottom:10px;color:white">맞춤 개발</div>
              <svg width="237" height="61" viewBox="0 0 237 61" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M237 30.3594L192 56.3401L192 4.37861L237 30.3594Z" fill="#828282"/>
              <rect y="17.3594" width="207" height="26" fill="#828282"/>
              </svg>
            </div>
            <div>
              <div class="margin-bottom-4 primary body3-bold">step 5</div>
              <div class="size-20" style="margin-bottom:10px;color:white">운영</div>
              <div style="width:207px;height:61px" class="flex-center">
                <div class="rec-prime"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 flex-between" style="padding:140px 0 240px">
        <div style="width:466px">
          <div class="margin-bottom-24 h5" style="color:white">기획부터 개발, 운영까지<br>올인원 솔루션</div>
          <div class="body2 gray">시장검증이 끝났거나 이미 비즈니스를 하고 있는 경우, 아이디어 검증을 위한 툴 보다는 제대로된 서비스 구축이 필요하게 됩니다.<br>런치팩은 Prime 서비스를 통해 각 단계 별 파트너들과 함께 비즈니스가 가능하도록 제대로 기획, 디자인, 개발, 운영(유지관리) 해 드립니다.</div>
        </div>
        <img src="/static/img/prime_all_in_one.png" style="height:420px">
      </div>

      <div class="col-12 flex-between contain">
        <div>
          <div class="primary-wrap margin-bottom-16">Idea & Plan</div>
          <div class="flex-align margin-bottom-16">
            <div class="sub-border-wrap" style="margin-right:12px">UX, UI Design</div>
            <div class="icon-wrap flex-center">
              <img src="/static/icon/u_arrow-down.svg" class="svg-primary" style="width:40px">
            </div>
          </div>
          <div class="flex-align margin-bottom-16">
            <div class="sub-border-wrap" style="margin-right:12px">Front, Backend Develop</div>
            <div class="icon-wrap flex-center">
              <img src="/static/icon/u_arrow-down.svg" class="svg-primary" style="width:40px">
            </div>
          </div>
          <div class="flex-align">
            <div class="sub-border-wrap" style="margin-right:12px">Operation service Platform</div>
          </div>
        </div>
        <div style="width:466px">
          <div class="margin-bottom-24 h5" style="color:white">기획이 가장 중요합니다.</div>
          <div class="body2 gray">구상하는 플랫폼 서비스의 BM을 고려하여 UX와 서비스 기획을 합니다.<br>서비스기획을 외주로 맡기는 경우, 실제 개발을 하기에 정의되지 않은 사항이 너무나 많이 발견되곤 합니다.
            그렇게 되면 가장 비용이 큰 개발 단계에서 많은 시간과 비용이 비효율적으로 소모되게 됩니다.<br>얼마나 기획을 꼼꼼하게, 그리고 고객님의 의견을 반영시켜서 하느냐가 전체 프로젝트 성공의 관건이 됩니다.<br>
            플랫폼파이는 플랫폼을 만드는 툴을 기획, 디자인, 개발 한 팀으로 일반적인 플랫폼 서비스 기획은 그 누구보다 자신이 있습니다.</div>
        </div>
      </div>

      <div class="col-12 flex-between contain">
        <div style="width:466px">
          <div class="margin-bottom-24 h5" style="color:white">프로젝트 단계 별 과금하기 때문에,<br>언제든 멈출 수 있습니다.</div>
          <div class="body2 gray">비즈니스를 하다보면 변수도 많고 시시각각 환경도 바뀝니다.<br>플랫폼파이는 사업자 고객님들 입장에서 더 좋은 조건으로 더 안심하고 진행될 수 있게 단계 별 과금을 하고 있습니다.
            기획서 작성까지 마무리 하고, 디자인 작업 전에 예산 확보를 하기 위한 시간을 버실 수도 있고,<br>갑작스런 사업방향 변경으로 프로젝트를 중단할 경우 이후 단계에 대한 매몰비용이 생기지 않게 하였습니다.</div>
        </div>
        <img src="/static/img/prime-project.png" style="height:316px">
      </div>

      <div class="col-12 flex-between contain">
        <img src="/static/img/prime-communication.png" style="height:456px">
        <div style="width:466px">
          <div class="margin-bottom-24 h5" style="color:white">플랫폼 운영 솔루션이 제공되며,<br>Prime 고객님의 전담 매니저가<br>다이렉트로 소통합니다.</div>
          <div class="body2 gray margin-bottom-16">일반적인 외주개발로 제작하는 경우, 맞춤으로 서비스 전체가 새로 만들어 지는 것이기 때문에 예산 내에서 고려되지 않은 비즈니스 운영단에 필요한 관리자 사이트나 기능이 부족하고 이는 비즈니스가 성장할 수록 추가 개발을 요구하게 되는 주 원인이 됩니다.<br>플랫폼파이는 이미 플랫폼 서비스에서 필요한 기능들을 솔루션에서 제공하고 있고, 추가기능이 생기더라도 그때그때 요청하며 퀄리티에 불안해 할 필요가 없습니다.</div>
          <div class="body2 gray">더불어 플랫폼 운영 솔루션(플랫폼 관리자 사이트)에서 직접 문의사항이나 서비스 버그 사항들을 리포팅 할 수 있으며 Prime 고객님의 전담 매니저가 상시 안내 및 답변 해 드리기 때문에 개발팀이 없이 비즈니스를 하더라도 불안하지 않습니다.</div>
        </div>
      </div>

      <div class="col-12 contain">
        <div class="subtitle1 margin-bottom-16 text-center" style="color:white">포트폴리오</div>
        <div class="body1 sub5 text-center" style="margin-bottom:95px">이미 많은 예비창업자 분들이 플랫폼파이 솔루션을 통해 아이디어를 검증하고 있습니다.</div>
        <div class="flex-justify">
          <div style="width:820px">
            <slick ref="slick" :options="slickOptions">
              <img v-for="(i,idx) in Array(9).fill().map((i,c) => (c % 3) + 1)" :key="'prime-ex-'+idx"
                    :src="`/static/img/prime-portfolio${i}.png`">
            </slick>
          </div>
        </div>
      </div>

      <div class="col-12 contain">
        <div class="subtitle1 margin-bottom-16 text-center" style="color:white">이번 달 신청 문의</div>
        <div class="body1 sub5 text-center margin-bottom-60">이미 많은 예비창업자 분들이 플랫폼파이 솔루션을 통해 아이디어를 검증하고 있습니다.</div>
        <div class="col-12 flex-justify">
          <rq-board></rq-board>
        </div>
      </div>

      <div class="col-12 flex-between contain">
        <div style="width:466px">
          <div class="margin-bottom-24 h5" style="color:white">누구든 시작 해 볼 수 있게,<br>Prime서비스는 상담으로 시작합니다.</div>
          <div class="body2 gray">서비스 제작이 아니어도 성공할 수 있는 방법은 많이 있습니다.<br>고객님과 상담을 나누고 Step 2 인 기획을 진행하는 것이 좋을지 결정합니다.</div>
        </div>
        <img src="/static/img/prime-counsel.png" style="height:393px">
      </div>

      <!-- <div class="lp-divider-gray1 padding-top-8"></div> -->
      <div class="form-container margin-bottom-32" id="regForm">
        <div class="margin-bottom-60 text-center">
          <div class="margin-bottom-12 h8 primary">step 1</div>
          <div class="margin-bottom-16 main h4">Prime 맞춤 제작 상담신청</div>
          <div class="body2 sub2">A to Z, 서비스의 모든 디자인과 기능을 내가 원하는대로 런칭<br>완벽함을 위한 VIP 솔루션</div>
        </div>

        <div class="form-subtitle body0-bold">원하시는 상담 수준을 알려주세요</div>

        <select-counsel @update:value="val=>counsel=val.value" style="margin:28px 0 76px"></select-counsel>
        <div class="form-subtitle body0-bold">제작하려는 플랫폼 서비스 정보를 알려주세요</div>
        <div v-for="(form, form_idx) in formData" :key="`form-${form_idx}`">
          <div class="margin-top-16">
            <div class="inquiry-reg-title subtitle5">
              {{ form.field!=='checkedVal'? form.label : '' }} {{ form.validate.required? '*':'' }}
            </div>
            <div class="inquiry-reg-content">
              <input-file placeholder="예) 소개서, 기획안 와이어프레임 등등"
                          @setValue="val=>form.value=val"
                          :value.sync="form.file_ext"
                          v-if="form.component_name==='input-single-file'"></input-file>
              <!--<input-single-file :value.sync="form.file_ext"
                                @setValue="val=>form.value=val"
                                placeholder="예) 소개서, 기획안 와이어프레임 등등"
                                v-if="form.component_name==='input-single-file'"></input-single-file>-->
              <component :is="form.component_name"
                        :ref="form.field"
                        v-bind.sync="form" v-else></component>
            </div>
          </div>
          <div v-if="form.field==='support'" class="form-subtitle body0-bold"
            style="margin-top:100px">신청하시는 Prime 고객님의 정보를 알려주세요</div>
        </div>
        <div class="margin-top-80 flex-center">
          <button class="button is-primary body2-bold" style="width:242px;height:48px" @click="saveData('pc')">제작 의뢰 상담 신청</button>
        </div>
      </div>

      <div class="flex-justify">
        <div class="flex-align">
          <div class="body1 gray" style="margin-right:24px">가볍게 플랫폼 아이디어를 실현하고 싶다면?</div>
          <div class="unselect subtitle4 primary" @click="routerPush('product_info')">Basic 멤버쉽 바로가기</div>
        </div>
      </div>
    </div>

    <div class="mobile" style="padding:60px 20px">
      <div class="contain-mo">
        <div class="flex-justify col-12">
          <div class="prime-bg-mo text-center">
            <div class="col-12 body0-medium primary">내 사업에 꼭 맞춘</div>
            <div class="col-12 body0-medium primary margin-bottom-24">커스텀 플랫폼을 만들고 싶다면?</div>
            <div class="h2 primary margin-bottom-24">Prime</div>
            <div class="body4 gray">완벽함을 위한 VIP 솔루션</div>
            <div class="body4 gray">A to Z, 서비스의 모든 디자인과 기능을 내가 원하는대로</div>
          </div>
        </div>
        <div class="col-12 flex-justify margin-bottom-40">
          <div style="width:216px">
            <div class="flex-between prime-item1">
              <div class="flex-align">
                <div class="primary body4-bold" style="margin-right:6px">step 1</div>
                <div class="body2" style="color:white">상담</div>
              </div>
              <div style="width:33px" class="flex-center">
                <div class="dot-prime-mo"></div>
              </div>
            </div>
            <div class="flex-between prime-item-arrow">
              <div class="flex-align">
                <div class="primary body4-bold" style="margin-right:6px">step 2</div>
                <div class="body2" style="color:white">기획</div>
              </div>
              <svg width="33" height="60" viewBox="0 0 33 60" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16.5 59.5039L30.3564 35.5039L2.64359 35.5039L16.5 59.5039Z" fill="white"/>
              <rect width="44" height="14" transform="matrix(0 1 1 0 9.5 0.503906)" fill="white"/>
              </svg>
            </div>
            <div class="flex-between prime-item-arrow">
              <div class="flex-align">
                <div class="primary body4-bold" style="margin-right:6px">step 3</div>
                <div class="body2" style="color:white">디자인</div>
              </div>
              <svg width="33" height="60" viewBox="0 0 33 60" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16.5 59.5039L30.3564 35.5039L2.64359 35.5039L16.5 59.5039Z" fill="#BDBDBD"/>
              <rect width="44" height="14" transform="matrix(0 1 1 0 9.5 0.503906)" fill="#BDBDBD"/>
              </svg>
            </div>
            <div class="flex-between prime-item-arrow">
              <div class="flex-align">
                <div class="primary body4-bold" style="margin-right:6px">step 4</div>
                <div class="body2" style="color:white">맞춤개발</div>
              </div>
              <svg width="33" height="60" viewBox="0 0 33 60" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16.5 59.5039L30.3564 35.5039L2.64359 35.5039L16.5 59.5039Z" fill="#828282"/>
              <rect width="44" height="14" transform="matrix(0 1 1 0 9.5 0.503906)" fill="#828282"/>
              </svg>
            </div>
            <div class="flex-between prime-item2">
              <div class="flex-align">
                <div class="primary body4-bold" style="margin-right:6px">step 5</div>
                <div class="body2" style="color:white">운영</div>
              </div>
              <div style="width:33px" class="flex-center">
                <div class="rec-prime-mo"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 contain-mo">
        <div class="flex-justify margin-bottom-40">
          <img src="/static/img/prime_all_in_one.png" style="height:216px">
        </div>
        <div class="text-center">
          <div class="margin-bottom-24 mo-section-title-bold" style="color:white">기획부터 개발, 운영 까지<br>올인원 솔루션</div>
          <div class="body4 gray" style="padding:0 36px">시장검증이 끝났거나 이미 비즈니스를 하고 있는 경우, 아이디어 검증을 위한 툴 보다는 제대로된 서비스 구축이 필요하게 됩니다. 플랫폼파이은 Prime 서비스를 통해 각 단계 별 파트너들과 함께 비즈니스가 가능하도록 제대로 기획, 디자인, 개발, 운영(유지관리) 해 드립니다.</div>
        </div>
      </div>

      <div class="col-12 contain-mo">
        <div class="flex-justify margin-bottom-40">
          <img src="/static/img/prime-plan.png" style="height:160px">
        </div>
        <div class="text-center">
          <div class="margin-bottom-8 mo-section-title-bold" style="color:white">기획이 가장 중요합니다.</div>
          <div class="body4 gray" style="padding:0 36px">구상하는 플랫폼 서비스의 BM을 고려하여 UX와 서비스 기획을 합니다. 서비스기획을 외주로 맡기는 경우, 실제 개발을 하기에 정의되지 않은 사항이 너무나 많이 발견되곤 합니다. 그렇게 되면 가장 비용이 큰 개발 단계에서 많은 시간과 비용이 비효율적으로 소모되게 됩니다.얼마나 기획을 꼼꼼하게, 그리고 고객님의 의견을 반영시켜서 하느냐가 전체 프로젝트 성공의 관건이 됩니다. 플랫폼파이는 플랫폼을 만드는 툴을 기획, 디자인, 개발 한 팀으로 일반적인 플랫폼 서비스 기획은 그 누구보다 자신이 있습니다.</div>
        </div>
      </div>

      <div class="col-12 contain-mo">
        <div class="flex-justify margin-bottom-40">
          <img src="/static/img/prime-project.png" style="height:148px">
        </div>
        <div class="text-center">
          <div class="margin-bottom-8 mo-section-title-bold" style="color:white">프로젝트 단계 별<br>과금하기 때문에,<br>언제든 멈출 수 있습니다.</div>
          <div class="body4 gray" style="padding:0 36px">비즈니스를 하다보면 변수도 많고 시시각각 환경도 바뀝니다. 플랫폼파이는 사업자 고객님들 입장에서 더 좋은 조건으로 더 안심하고 진행될 수 있게 단계 별 과금을 하고 있습니다. 기획서 작성까지 마무리 하고, 디자인 작업 전에 예산 확보를 하기 위한 시간을 버실 수도 있고, 갑작스런 사업방향 변경으로 프로젝트를 중단할 경우 이후 단계에 대한 매몰비용이 생기지 않게 하였습니다.</div>
        </div>
      </div>

      <div class="col-12 contain-mo">
        <div class="flex-justify margin-bottom-40">
          <img src="/static/img/prime-communication.png" style="height:200px">
        </div>
        <div class="text-center">
          <div class="margin-bottom-8 mo-section-title-bold" style="color:white">플랫폼 운영 솔루션이 제공되며,<br>Prime 고객님의 전담 매니저가<br>다이렉트로 소통합니다.</div>
          <div class="body4 gray" style="padding:0 36px">일반적인 외주개발로 제작하는 경우, 맞춤으로 서비스 전체가 새로 만들어 지는 것이기 때문에 예산 내에서 고려되지 않은 비즈니스 운영단에 필요한 관리자 사이트나 기능이 부족하고 이는 비즈니스가 성장할 수록 추가 개발을 요구하게 되는 주 원인이 됩니다.<br>플랫폼파이는 이미 플랫폼 서비스에서 필요한 기능들을 솔루션에서 제공하고 있고, 추가기능이 생기더라도 그때그때 요청하며 퀄리티에 불안해 할 필요가 없습니다.<br>더불어 플랫폼 운영 솔루션(플랫폼 관리자 사이트)에서 직접 문의사항이나 서비스 버그 사항들을 리포팅 할 수 있으며 Prime 고객님의 전담 매니저가 상시 안내 및 답변 해 드리기 때문에 개발팀이 없이 비즈니스를 하더라도 불안하지 않습니다.</div>
        </div>
      </div>

      <div class="col-12 margin-bottom-60">
        <div class="flex-justify margin-bottom-40">
          <img src="/static/img/prime-counsel.png" style="height:174px">
        </div>
        <div class="text-center">
          <div class="margin-bottom-8 mo-section-title-bold" style="color:white">누구든 시작 해 볼 수 있게,<br>상담으로 시작합니다.</div>
          <div class="body4 gray" style="padding:0 36px">서비스 제작이 아니어도 성공할 수 있는 방법은 많이 있습니다. 고객님과 상담을 나누고 Step 2 인 기획을 진행하는 것이 좋을지 결정합니다.</div>
        </div>
      </div>

      <div class="col-12 margin-bottom-60">
        <div class="margin-bottom-32 mo-section-title-bold text-center" style="color:white">포트폴리오</div>
        <div class="col-12 flex-justify">
          <slick ref="slick" :options="slickOptionsMobile" style="width:100%">
            <img v-for="(i,idx) in Array(9).fill().map((i,c) => (c % 3) + 1)"  :key="'prime-m-ex-'+idx"
                  :src="`/static/img/prime-portfolio${i}.png`">
          </slick>
        </div>
      </div>

      <div class="col-12 margin-bottom-60">
        <div class="margin-bottom-20 mo-section-title-bold text-center" style="color:white">신청문의</div>
        <rq-board class="col-12"></rq-board>
      </div>

      <div class="col-12 form-container-mo margin-bottom-16" id="regFormMo">
        <div class="form-title text-center">
          <div class="h7 main margin-bottom-8">Prime 맞춤 제작<br>상담신청</div>
          <div class="body4 sub2">A to Z, 서비스의 모든 디자인과 기능을 내가 원하는대로 런칭 완벽함을 위한 VIP 솔루션</div>
        </div>
        <div class="h8 sub form-subtitle" style="padding:10px 0">원하시는 상담 수준을 알려주세요.</div>
        <select-counsel @update:value="val=>counsel=val.value" style="margin:24px 0 48px"></select-counsel>
        <div class="h8 sub form-subtitle" style="padding:10px 0">제작하려는 플랫폼 서비스 정보를 알려주세요.</div>
        <div v-for="(form, form_idx) in formData" :key="`form-${form_idx}`">
          <div :class="{'margin-top-20':form.field!=='support','margin-top-24':form.field==='support'}">
            <div class="subtitle7 sub" v-if="form.field !== 'checkedVal'">{{ form.label }}</div>
            <input-file class="margin-top-8"
                        placeholder="예)소개서, 기획안 와이어프레임"
                        @setValue="val=>form.value=val"
                        :value.sync="form.file_ext"
                        v-if="form.component_name==='input-single-file'"></input-file>
            <!--<input-single-file class="margin-top-8"
                              :value.sync="form.file_ext"
                              @setValue="val=>form.value=val"
                              placeholder="예) 소개서, 기획안 와이어프레임 등등"
                              v-if="form.component_name==='input-single-file'"></input-single-file>-->
            <component :is="form.component_name"
                      class="margin-top-8"
                      v-bind.sync="form" v-else></component>
          </div>
          <div v-if="form.field==='support'" class="h8 sub form-subtitle margin-top-60" style="padding:10px 0">신청하시는 고객님의 정보를 알려주세요.</div>
        </div>

        <!--<div class="flex-align margin-top-24 body6">
          <lp-check-bool :checkedVal.sync="checkedVal"
                        label="개인 정보 수집 및 이용안내 동의"></lp-check-bool>
          <span class="primary">(필수)</span>
          <a class="sub3 text-underline margin-left-8 unselect" href="privacy" target="_blank">약관보기</a>
        </div>-->

        <button class="button is-primary margin-top-40 body2-bold"
                style="width:100%;height:48px;"
                @click="saveData('mobile')"
                v-if="!$route.query.id">제작 의뢰 상담 신청</button>
      </div>
      <div class="margin-bottom-8 gray body5 text-center">가볍게 플랫폼 아이디어를 실현하고 싶다면?</div>
      <div class="primary size-14 text-center" @click="routerPush('product_info')"
        style="text-decoration:underline">Basic 멤버쉽 바로가기 </div>
    </div>

    <div class="success-modal-pc">
      <sweet-modal ref="successModal" overlay-theme="dark" :width="modalWidth" :blocking="true"
                   :enable-mobile-fullscreen="false"
                   @close="$refs.successModal.close()" :hide-close-button="true">
        <div class="modal-container">
          <div class="subtitle4 main margin-top-12">견적신청이 완료되었습니다.</div>
          <div class="flex-between margin-top-64">
            <button class="button body2-bold sub2"
                    style="width:50%;height:52px"
                    @click="$router.replace('/home')">홈으로 가기</button>
            <button class="button is-primary body2-bold margin-left-16"
                    style="width:50%;height:52px"
                    @click="$router.replace(`/service_inquiry_detail?id=${inquiry_id}`)">견적 신청 확인하기</button>
          </div>
        </div>
      </sweet-modal>
    </div>

    <div class="success-modal-mobile">
      <sweet-modal ref="successModalMobile" overlay-theme="dark" :width="modalWidth" :blocking="true"
                   :enable-mobile-fullscreen="false"
                   @close="$refs.successModalMobile.close()" :hide-close-button="true">
        <div class="modal-container">
          <div class="body4 main margin-top-36">견적신청이 완료되었습니다.</div>
          <div class="flex-between margin-top-52">
            <button class="button body5-medium sub2"
                    style="width:50%;height:36px"
                    @click="$router.replace('/home')">홈으로 가기</button>
            <button class="button is-primary body5-medium margin-left-16"
                    style="width:50%;height:36px"
                    @click="$router.replace(`/service_inquiry_detail?id=${inquiry_id}`)">견적 신청 확인하기</button>
          </div>
        </div>
      </sweet-modal>
    </div>
  </div>
</template>
<script>
  import LpCheckBool from "../module/LpCheckBool";
  import LpRadio from "../module/LpRadio";
  import InputSingleFile from "../module/InputSingleFile";
  import LpText from "../component/LpText";
  import LpTextArea from "../component/LpTextArea";
  import LpNumberUnit from "../component/LpNumberUnit";
  import RqBoard from "../component/RqBoard";
  import FormMixin from "../../mixins/FormMixin";
  import LpCheckBoxArray from "../component/LpCheckBoxArray";
  import { SweetModal } from 'sweet-modal-vue';
  import InputFile from "../module/InputFile";
  import SelectCounsel from "../component/SelectCounsel";
  import Slick from 'vue-slick';

  export default {
    name: "ServiceInquiryReg",
    components: {
      SelectCounsel,
      InputFile,
      Slick,
      RqBoard,
      LpTextArea, LpText, InputSingleFile, LpCheckBool, LpRadio, LpNumberUnit, LpCheckBoxArray, SweetModal},
    mixins: [
      FormMixin
    ],
    data() {
      return {
        counsel: '',
        modalWidth: 560,
        formData: [],
        checkedVal: false,
        supportOption: [
          {label: '모바일 웹 단독(PC로 접속 시 모바일 웹 뷰 제공)', value: '모바일 웹 단독(PC로 접속 시 모바일 웹 뷰 제공)'},
          {label: 'PC 웹 단독(모바일 접속 시 PC 웹 뷰 제공)', value: 'PC 웹 단독(모바일 접속 시 PC 웹 뷰 제공)'},
          {label: 'PC 웹 + 모바일 웹', value: 'PC 웹 + 모바일 웹'},
          {label: 'PC 웹 + 모바일 웹 + 모바일 앱', value: 'PC 웹 + 모바일 웹 + 모바일 앱'}
        ],
        inquiry_id: 0,
        slickOptions: {
          centerMode: false,
          variableWidth: false,
          infinite: true,
          autoplay: true,
          centerPadding:'20px',
          slidesToShow: 1,
          slidesToScroll: 1,
          speed: 300,
          dots: true,
          prevArrow: '<div class="arrow-left" style="background-color:black;border:1px solid white;border-radius:50%"><img class="arrow-img svg-white" src="/static/icon/fi_chevron-left.svg" @click="$refs.slick.prev()"></div>',
          nextArrow: '<div class="arrow-right" style="background-color:black;border:1px solid white;border-radius:50%"><img class="arrow-img svg-white" src="/static/icon/fi_chevron-right.svg" @click="$refs.slick.next()"></div>'
        },
        slickOptionsMobile: {
          centerMode: false,
          variableWidth: false,
          arrows: false,
          infinite: true,
          autoplay: true,
          centerPadding:'12px',
          slidesToShow: 1,
          slidesToScroll: 1,
          speed: 300,
          dots: true
        },
      }
    },
    created() {
      if(window.innerWidth<500) {
        this.modalWidth = 'none'
      }
      /*this.$nextTick(()=>{
        this.$refs.successModalMobile.open();
      })*/
      this.setForm();
    },
    mounted() {
      let isMobile = matchMedia("(max-width: 1024px)").matches;

      this.$nextTick(() => {
        if (this.$route.query.form) {
          setTimeout(() => {
            let element;
            let offset;
            if(isMobile) {
              element = document.getElementById(`regFormMo`);
              offset = window.pageYOffset + element.getBoundingClientRect().top - 60;
            }
            else {
              element = document.getElementById(`regForm`);
              offset = window.pageYOffset + element.getBoundingClientRect().top - 80;
            }
            window.scrollTo({
              top: offset,
              behavior: 'smooth'
            })
          }, 500)
        }
      });
    },
    watch: {
      enableCheckSignup(n) {
        if(n) {
          let items = [
            {
              label: '비밀번호',
              placeholder: '비밀번호를 입력해주세요.',
              maxLength: 30,
              field: 'password',
              type: 'password',
              value: '',
              component_name: 'lp-text',
              errorMsg: '',
              validate: {
                num: true,
                alpha_g: true,
                alpha_s: true,
                special: true,
                max_length: 30,
                min_length: 1,
                valid_required: true,
                required: true
              },
            },
            {
              label: '비밀번호 확인',
              placeholder: '비밀번호 확인을 입력해주세요.',
              maxLength: 30,
              field: 'password_new',
              type: 'password',
              value: '',
              component_name: 'lp-text',
              errorMsg: '',
              validate: {
                num: true,
                alpha_g: true,
                alpha_s: true,
                special: true,
                max_length: 30,
                min_length: 1,
                valid_required: true,
                required: true
              },
            }
          ]
          this.formData = this.formData.concat(items);
        } else {
          let pass = this.formData.findIndex(item=>{ return item.field === 'password'})
          if(pass > -1) {
            this.formData.remove(pass);
            let password_new = this.formData.findIndex(item=>{ return item.field === 'password_new'})
            this.formData.remove(password_new);
          }
        }
      }
    },
    computed: {
      // 회원가입 체크 여부
      enableCheckSignup() {
        let item = this.findItem(this.formData, 'field', 'check_signup');
        return item && item.value;
      }
    },
    methods: {
      setForm() {
        let checkFontSize = '15px';
        let width = '270px';
        if(window.innerWidth<500) {
          checkFontSize = '12px';
          width = '100%';
        }
        this.formData = [
          {
            label: '서비스 이름',
            placeholder: '서비스 이름을 입력해주세요.',
            maxLength: 30,
            field: 'service_name',
            type: 'text',
            value: '',
            component_name: 'lp-text',
            errorMsg: '',
            validate: {
              num: true,
              alpha_g: true,
              alpha_s: true,
              special: true,
              max_length: 30,
              min_length: 1,
              valid_required: true,
              required: true
            }
          },
          {
            label: '서비스 소개',
            placeholder: '서비스 소개를 적어주세요.',
            maxLength: 10000,
            field: 'content',
            type: 'text',
            value: '',
            component_name: 'lp-text-area',
            errorMsg: '',
            validate: {
              num: true,
              alpha_g: true,
              alpha_s: true,
              special: true,
              max_length: 10000,
              min_length: 1,
              valid_required: true,
              required: true
            },
          },
          {
            label: '제작예산',
            placeholder: "0",
            maxLength: 30,
            field: 'account',
            type: 'number',
            value: 0,
            component_name: 'lp-number-unit',
            unit: '만원',
            width: width,
            errorMsg: '',
            validate: {
              num: true,
              alpha_g: true,
              alpha_s: true,
              special: true,
              max_length: 1000000000000,
              min_length: 0,
              valid_required: false
            },
          },
          {
            label: '희망 소요기간',
            placeholder: "0",
            maxLength: 30,
            field: 'hope_date',
            type: 'number',
            value: 0,
            component_name: 'lp-number-unit',
            unit: '개월',
            width: width,
            errorMsg: '',
            validate: {
              num: true,
              alpha_g: true,
              alpha_s: true,
              special: true,
              max_length: 1000,
              min_length: 0,
              valid_required: false
            },
          },
          {
            label: '참고자료',
            placeholder: '예) 소개서, 기획안 와이어프레임 등등',
            field: 'files',
            value: '',
            component_name: 'input-single-file',
            file_ext: '',
            errorMsg: '',
            validate: {
              num: true,
              alpha_g: true,
              alpha_s: true,
              special: true,
              max_length: 30,
              min_length: 1,
              valid_required: false
            },
          },
          {
            label: '서비스 지원환경',
            placeholder: '',
            field: 'support',
            value: '모바일 웹 단독(PC로 접속 시 모바일 웹 뷰 제공)',
            block: true,
            data: [
              {label: '모바일 웹 단독(PC로 접속 시 모바일 웹 뷰 제공)', value: '모바일 웹 단독(PC로 접속 시 모바일 웹 뷰 제공)'},
              {label: 'PC 웹 단독(모바일 접속 시 PC 웹 뷰 제공)', value: 'PC 웹 단독(모바일 접속 시 PC 웹 뷰 제공)'},
              {label: 'PC 웹 + 모바일 웹', value: 'PC 웹 + 모바일 웹'},
              {label: 'PC 웹 + 모바일 웹 + 모바일 앱', value: 'PC 웹 + 모바일 웹 + 모바일 앱'}
            ],
            label_name: 'label',
            value_name: 'value',
            component_name: 'lp-radio',
            errorMsg: '',
            validate: {
              num: true,
              alpha_g: true,
              alpha_s: true,
              special: true,
              max_length: 30,
              min_length: 1,
              valid_required: true,
              required: true
            },
          },
          {
            label: '성함(혹은 회사명)',
            placeholder: '성함을 입력해주세요.',
            maxLength: 30,
            field: 'name',
            type: 'text',
            value: '',
            component_name: 'lp-text',
            errorMsg: '',
            validate: {
              num: true,
              alpha_g: true,
              alpha_s: true,
              special: true,
              max_length: 30,
              min_length: 1,
              valid_required: true,
              required: true
            },
          },
          {
            label: '연락처',
            placeholder: '연락처를 입력해주세요.',
            maxLength: 30,
            field: 'phone',
            type: 'phone',
            value: '',
            component_name: 'lp-text',
            errorMsg: '',
            validate: {
              num: true,
              alpha_g: true,
              alpha_s: true,
              special: true,
              max_length: 30,
              min_length: 1,
              valid_required: true,
              required: true
            },
          },
          {
            label: '이메일',
            placeholder: '이메일을 입력해주세요.',
            maxLength: 30,
            field: 'email',
            type: 'email',
            value: '',
            component_name: 'lp-text',
            errorMsg: '',
            validate: {
              num: true,
              alpha_g: true,
              alpha_s: true,
              special: true,
              max_length: 30,
              min_length: 1,
              valid_required: true,
              required: true
            },
          },
          {
            label: '이용약관 및 개인정보처리방침에 동의하기',
            items: [
              {
                label: '이용약관 및 개인정보처리방침에 동의하기',
                labelStyle: {
                  fontSize: checkFontSize,
                  lineHeight: '15px',
                  color: '#555'
                }
              },
              {
                label: '(필수)', labelStyle: {
                  fontSize: checkFontSize,
                  lineHeight: '15px',
                  color: '#FF6600'
                }
              },
              {
                label: '약관보기', link: 'term',
                labelStyle: {
                  color: '#828282',
                  fontSize: checkFontSize,
                  textDecoration: 'underline',
                  marginLeft: '8px'
                }
              }
            ],
            field: 'checkedVal',
            type: 'check',
            value: false,
            component_name: 'lp-check-box-array',
            errorMsg: '',
            validate: {
              valid_required: true
            },
          },
          {
            label: '개인정보 처리 위탁에 동의',
            items: [
              {
                label: '개인정보 처리 위탁에 동의',
                labelStyle: {
                  fontSize: checkFontSize,
                  lineHeight: '15px',
                  color: '#555'
                }
              },
              {
                label: '(필수)', labelStyle: {
                  fontSize: checkFontSize,
                  lineHeight: '15px',
                  color: '#FF6600'
                }
              },
              {
                label: '약관보기', link: 'privacy',
                labelStyle: {
                  color: '#828282',
                  fontSize: checkFontSize,
                  textDecoration: 'underline',
                  marginLeft: '8px'
                }
              }
            ],
            field: 'checkedVal',
            type: 'check',
            value: false,
            component_name: 'lp-check-box-array',
            errorMsg: '',
            validate: {
              valid_required: true
            },
          }
        ];
        let name = '';
        let email = '';
        let phone = '';
        if (this.user.user_id) {
          let user = this.cloneItem(this.$store.getters.user)
          name = user.name;
          email = user.email;
          phone = user.phone;
          this.formData.forEach(item=>{
            if(item.field==='name') {
              item.value = name;
            } else if(item.field==='email') {
              item.value = email;
            } else if(item.field==='phone') {
              item.value = phone;
            }
          })
        } else {
          let item = {
            label: '',
            items: [
              {
                label: '위 정보로 회원가입하여 다음부터 내 Prime 제작 현황에서 확인할 수 있게 합니다',
                labelStyle: {
                  fontSize: checkFontSize,
                  lineHeight: '15px',
                  color: '#555'
                }
              },
              {
                label: '(필수)', labelStyle: {
                  fontSize: checkFontSize,
                  lineHeight: '15px',
                  color: '#FF6600'
                }
              }
            ],
            field: 'check_signup',
            type: 'check',
            value: false,
            component_name: 'lp-check-box-array',
            errorMsg: '',
            validate: {
              valid_required: false
            },
          }
          this.formData.push(item);
        }
        // this.getData();
      },
      keyUpPhone() {
        this.value.phone = this.autoHyphenPhone(this.value.phone);
      },
      setNumber(e, max_length) {
        if (e.target.value.length > max_length) {
          this.value.account = parseInt(e.target.value.substr(0, max_length));
        } else if (e.target.value.length === 0) {
          this.value.account = 0;
        } else {
          this.value.account = parseInt(e.target.value);
        }
      },
      getData() {
        let url = `public/launchpack/inquiry/${this.$route.query.id}`;
        this.$axios.get(url).then(res=>{
          this.formData.forEach(item=>{
            item.value = res.data[item.field]
          })
        })
      },
      saveData(device) {
        let result = this.getParamData(this.formData);
        if(result.enable) {
          if(this.isLogin) {
            this.regInquiry(result, device);
          } else {
            if(!this.enableCheckSignup) {
              this.toast('필수 사항에 체크하신 후 진행해주세요.')
            } else if(result.params.password !== result.params.password_new) {
              this.toast('비밀번호 확인값이 일치하지 않습니다.')
            } else {
              if(this.enableCheckSignup) {
                let params = {
                  username: result.params.email,
                  name: result.params.name,
                  password: result.params.password,
                  phone: result.params.phone,
                  email: result.params.email,
                }
                this.$axios.post('auth/v1/user/signup', params).then(res=>{
                  if(res.status === 200) {
                    this.$store.commit('setUser', res.data.user);
                    this.setAuthHeader();
                    this.regInquiry(result, device);
                  }
                }).catch(error => {
                  if (error.response) {
                    let res = error.response;
                    this.toast(res.data.message);
                  }
                })
              } else {
                this.regInquiry(result, device);
              }
            }
          }
        }
      },
      regInquiry(result, device) {
        let params = this.cloneItem(result.params);
        if (this.isLogin) {
          params.user_id = this.user.user_id;
        }
        params.counsel = this.counsel;
        params.channel = '플랫폼파이 / PRIME';
        let url = 'public/launchpack/v1/inquiry';
        this.$axios.post(url, params).then(res => {
          if (res.status === 200) {
            this.routeGa('', '주문제작 견적 신청', '등록완료', '응답');
            this.inquiry_id = res.data;
            if(device==='pc') this.$refs.successModal.open();
            else this.$refs.successModalMobile.open();
          }
        }).catch(err => {
          if (err.response.data.message) {
            this.toast(err.response.data.message);
          } else if (err.response.data.detail) {
            this.toast(err.response.data.detail);
          } else {
            this.toast('오류가 발생하였습니다. 관리자에게 문의주세요.');
          }
        });
      }
    }
  }
</script>
<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .pc .inquiry-reg-title
    width 30%

  .pc .inquiry-reg-content
    width 70%
    margin-top 8px
  .modal-container
    padding 20px

  .container
    padding 140px 0

  .arrow-box
    padding 24px 0 60px
    border-bottom 1px solid $sub2

  .dot-prime
    width 29px
    height 29px
    background-color white
    border-radius 50%

  .rec-prime
    width 207px
    height 26px
    background-color $sub2

  .rec-prime-mo
    width 14px
    height 44px
    background-color $sub2

  .prime-bg
    background url(/static/img/home/img_prime_back.png)
    background-repeat no-repeat
    background-position center
    background-size contain
    height 380px

  .prime-bg-mo
    background url(/static/img/home/img_prime_back.png)
    background-repeat no-repeat
    background-position center
    background-size contain
    height 260px

  .primary-wrap
    border-radius 50px
    background linear-gradient(322.3deg, #FF6600 22.51%, #F43B00 100%)
    color white
    font-size 28px
    font-weight 700
    text-align center
    width 230px
    height 72px
    line-height 72px

  .sub-border-wrap
    border-radius 50px
    border 1px solid $sub2
    padding 14px 40px
    color white
    font-size 28px
    font-weight 500
    text-align center

  .icon-wrap
    border 1px dashed $primary
    border-radius 50%
    width 72px
    height 72px

  .contain
    margin-bottom 240px

  .contain-mo
    margin-bottom 100px

  .prime-item1
    border-bottom 1px dashed $primary-dark
    padding 14px 16px 14px 0

  .prime-item2
    border-bottom 1px solid $primary-dark
    padding-right 16px

  .prime-item-arrow
    border-bottom 1px dashed $primary
    padding-right 16px

  .dot-prime-mo
    width 22px
    height 22px
    background-color white
    border-radius 50%

  .form-container
    background-color white
    padding 50px 40px
    border-radius 12px
    width 100%

  .form-container-mo
    background-color white
    padding 24px 16px 16px
    border-radius 8px
    width 100%

  .form-subtitle
    padding 16px 0
    color $main
    border-top 1px solid $main
    border-bottom 1px solid $gray2

  .form-title
    padding-bottom 40px
    border-bottom 1px solid $main

</style>
<style lang="stylus">
  .success-modal-mobile .sweet-modal.is-visible
    height 184px

  .success-modal-mobile .sweet-modal.is-alert .sweet-content
    padding-top 0
    padding-bottom 0
</style>
